.seo-footer-links {
	background-color: #fff;
	padding: 10px 20px;
	.caption {
		font-weight: 700;
		margin-right: 8px;
	}
	.links a {
		margin-right: 5px;
		color: #999;
	}
	.links a:hover {
		text-decoration: underline;
	}
}

.seo-footer-links p {
	margin: 12px 0;
}
