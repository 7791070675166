.footer-component {
	background-color: #eef1f6;
	.footer-component-wrapper {
		margin: 0px auto;
		width: 1180px;
		padding: 10px 0 0 0;
		.seo-breadcrumb {
			margin-bottom: 10px;
		}
		.seo-breadcrumb > h3 {
			display: inline-block;
		}
		.seo-breadcrumb > h3 > a {
			color: #333;
		}
		.disclaimer {
			background-color: #fff;
			padding: 20px;
			margin-bottom: 1px;
		}
	}
}
