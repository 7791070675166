.seo-result-wrapper {
	background-color: #eef1f6;
	.seo-flight-list {
		width: 1180px;
		margin: 0 auto;
		.seo-no-result {
			position: relative;
			background-color: #fff9ea;
			min-height: 50px;
			padding: 15px 15px 15px 100px;
			.seo-icon-noresult {
				position: absolute;
				left: 20px;
				top: 50%;
				margin-top: -29px;
				background-position: -278px -170px;
				width: 58px;
				height: 58px;
				background-image: -webkit-image-set(url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite.png?v6) 1x, url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite@2x.png?v6) 2x);
				background-repeat: no-repeat;
			}
			.seo-noreuslt-title {
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;
			}
		}
		.seo-no-result:after {
			display: inline-block;
			content: '';
			min-height: 50px;
			vertical-align: middle;
		}
	}
}
