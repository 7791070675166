@import '../../styles/list/variable.scss';

.seo-result-wrapper {
	.list-content {
		.list-content-item-oneway {
			margin-bottom: 2px;
			background-color: $white;
			.item-box {
				transition: box-shadow 0.15s, -webkit-box-shadow 0.15s;
				padding: 0 210px 0 10px;
				position: relative;
				.box-row {
					padding: 18px 0 13px 0;
					.airline {
						padding-left: 70px;
						position: relative;
						width: 210px;
						display: inline-block;
						vertical-align: middle;
						.airline-logo {
							position: absolute;
							width: 40px;
							height: 35px;
							top: 50%;
							margin-top: -18px;
							left: 20px;
						}
						.airline-name {
							font-size: 14px;
							margin-bottom: 10px;
						}
						.plane {
							display: block;
							margin-top: 5px;
							.plane-No {
								display: inline-block;
								vertical-align: top;
								margin-right: 8px;
								padding-bottom: 2px;
								color: #0086f6;
								border-bottom: none;
								.high-light {
									color: #00b87a;
								}
							}
						}
					}
					.detail {
						width: 410px;
						display: inline-block;
						vertical-align: middle;
						text-align: center;
						.depart-box {
							position: relative;
							display: inline-block;
							vertical-align: top;
							width: 110px;
							text-align: right;
						}
						.arrow-box {
							position: relative;
							display: inline-block;
							vertical-align: top;
							width: 120px;
							margin: 0 10px;
							padding-top: 6px;
							.arrow-oneway {
								display: inline-block;
								vertical-align: middle;
								background-position: -126px -200px;
								width: 120px;
								height: 22px;
								background-image: url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite.png?v6);
								background-image: -webkit-image-set(url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite.png?v6) 1x, url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite@2x.png?v6) 2x);
								background-repeat: no-repeat;
							}
						}
						.arrive-box {
							position: relative;
							display: inline-block;
							vertical-align: top;
							width: 110px;
							text-align: left;
						}
						.depart-box,
						.arrive-box {
							.time {
								font-size: 30px;
								.day {
									position: absolute;
									font-size: 12px;
									color: #398efe;
									left: 82px;
									top: -2px;
									white-space: nowrap;
								}
							}
							.airport {
								margin-bottom: 4px;
							}
						}
					}
					.arrival-punctuality {
						display: inline-block;
						color: #0086f6;
						font-size: 12px;
						width: 96px;
						text-align: right;
						padding-right: 8px;
						vertical-align: middle;
					}
					.flight-tags {
						width: 160px;
						text-align: right;
						display: inline-block;
						vertical-align: middle;
					}
				}
				.box-operate {
					display: inline-block;
					width: auto;
					position: absolute;
					right: 20px;
					top: 0;
					bottom: 0;
					text-align: right;
					.price {
						display: inline-block;
						vertical-align: middle;
						text-align: right;
						.price-detail {
							font-size: 24px;
							color: #0086f6;
							line-height: 26px;
							.price {
								color: #0086f6;
								line-height: 26px;
								font-size: 30px;
							}
							.price dfn {
								color: #0086f6;
								font-size: 18px;
							}
							.qi {
								color: #0086f6;
								font-size: 12px;
							}
						}
						.rate-detail {
							color: #919ca2;
							line-height: 12px;
							font-size: 12px;
						}
					}
					.action {
						position: relative;
						display: inline-block;
						vertical-align: middle;
						width: auto;
						margin-left: 8px;
						.btn-book {
							text-align: center;
							border: 1px solid transparent;
							border-radius: 4px;
							display: inline-block;
							width: auto;
							padding: 0 16px;
							height: 32px;
							line-height: 32px;
							font-size: 14px;
							color: #fff;
							font-weight: 600;
							background-image: linear-gradient(to right, #ffa50a, #ff7700);
						}
					}
				}
				.box-operate:after {
					content: '';
					display: inline-block;
					width: 0;
					height: 100%;
					vertical-align: middle;
				}
			}
		}
		.list-content-item:hover {
			box-shadow: 0 4px 15px rgb(0 0 0 / 18%);
			z-index: 3;
		}
	}
}
