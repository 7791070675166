@import '../../styles/list/variable.scss';

.seo-result-wrapper {
	.list-header {
    margin-bottom: 4px;
    padding-right: 260px;
		.city-info {
			position: relative;
			height: 50px;
			line-height: 1;
      background-color: none;
      display: inline-block;
			.tripType {
				position: relative;
				display: inline-block;
				padding: 0 5px 0 10px;
				font-size: 18px;
				line-height: 45px;
				color: $black;
			}
			.departCity,
			.arriveCity {
				font-size: 24px;
				font-weight: bold;
				display: inline-block;
				line-height: 14px;
				vertical-align: baseline;
			}
			.arrow-gray {
				margin: 0 4px;
				display: inline-block;
				vertical-align: baseline;
				background-position: -94px -169px;
				width: 21px;
				height: 14px;
				background-image: url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite.png?v6);
				background-image: -webkit-image-set(url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite.png?v6) 1x, url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite@2x.png?v6) 2x);
				background-repeat: no-repeat;
			}
		}
		.date-info {
			display: inline-block;
      font-size: 18px;
      margin-left: 10px;
      color: $black;
			}
			.week {
        margin-left: 4px;
			}
		}
}
